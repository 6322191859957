<template>
  <layout page="teacher-class-list">
    <page-title icon="lni lni-school-bench-alt" :breadcrumb="breadcrumb">Turmas</page-title>
    <card-list>
      <card-list-header title="Minhas Turmas" />
      <div class="card-content">
        <div v-if="classes.length > 0" class="table-container">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Nome</th>
                <td class="has-text-right">Ações</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(_class) in classes" :key="_class.id">
                <td>{{ _class.name }}</td>
                <td class="has-text-right">
                  <router-link class="button is-link is-small mr-1" :to="`/p/t/turmas/${_class.id}/alunos`" title="Ver Alunos">
                    <i class="lni lni-users"></i>
                  </router-link>
                  <router-link class="button is-link is-small mr-1" :to="`/p/t/turmas/${_class.id}/atividades`" title="Ver Atividades">
                    <i class="lni lni-eye"></i>
                  </router-link>
                  <router-link class="button is-primary is-small mr-1" :to="`/p/t/turmas/${_class.id}/atividades/nova`" title="Escrever Atividade">
                    <i class="lni lni-write"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <empty-list v-else entity="turma" />
      </div>
    </card-list>
  </layout>
</template>

<script>
import api from '@/api'
import { mapState } from 'vuex'
import { DASHBOARD, CLASS_LIST } from '@/config/breadcrumb/teacher'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import CardList from '@/components/CardList'
import CardListHeader from '@/components/CardList/Header'
import EmptyList from '@/components/ui/EmptyList'

export default {
  name: 'TeacherClassList',
  components: {
    Layout,
    PageTitle,
    CardList,
    CardListHeader,
    EmptyList
  },
  created () {
    api.get(`/teachers/${this.user.id}/classes`).then(res => {
      this.classes = res.data
    })
  },
  data () {
    return {
      classes: []
    }
  },
  computed: {
    ...mapState(['user']),
    breadcrumb () {
      return [
        DASHBOARD,
        { ...CLASS_LIST, isActive: true }
      ]
    }
  }
}
</script>
